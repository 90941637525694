import React from 'react'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import FaqCard from '@site/components/molecules/FaqCard'
import { Faq as FaqInterface } from '@shared/interfaces/Faq'
import Texts from '@site/assets/constants/faq/texts'

const Faq = ( { faqs }: Faq.Props ): React.ReactElement => {
  return (
    <S.Container
      className="faq__container"
    >
      <S.Content>
        <Typography
          variant="title-medium-bold"
          color="TAG3-400"
          as="h2"
        >
          {Texts.TITLE}
        </Typography>

        {faqs.map( ( faq, i ) => (
          <FaqCard
            faq={ faq.node }
            key={ i as any }
          />
        ) )}
      </S.Content>
    </S.Container>
  )
}

namespace Faq{
  export interface Props {
    faqs: {
      node: FaqInterface
    }[]
  }
}

export default Faq
