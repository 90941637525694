interface Texts {
  BIRTHDAY_TAG: string
  WATCH: string
  LEARN_MORE: string
}

export const VideoSectionTexts: Texts = {
  'BIRTHDAY_TAG': 'Como vai ser o aniversário de 8 anos da TAG?',
  'WATCH': 'Assista para descobrir!',
  'LEARN_MORE': 'Clique e saiba mais',
}
