import React, { useState } from 'react'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { Faq } from '@shared/interfaces/Faq'
import { isMobile } from 'react-device-detect'
import ArrowIcon from '@site/images/faq/arrow-icon.svg'
import { AnimatePresence, motion } from 'framer-motion'
import { dispatchGlobalEvent } from '@shared/services/tracking'
import ReactMarkdown from 'react-showdown'

const FaqCard = ( { faq }: FaqCard.Props ): React.ReactElement => {
  const [ isOpen, setIsOpen ] = useState( false )

  function handleAnswerClick() {
    if ( !isOpen ) {
      dispatchGlobalEvent( 'Site', 'faq_open_question', faq.eventLabel )
    }

    setIsOpen( !isOpen )
  }

  return (
    <S.Container
      className="faq-card__container"
      id={ `faq__${faq.eventLabel}` }
    >
      <S.Question
        onClick={ handleAnswerClick }
        $isOpen={ isOpen }
      >
        <Typography
          variant={ isMobile ? 'subtitle-medium-bold' : 'subtitle-large-bold' }
          color={ isOpen ? 'TAG3-400' : 'TAG1-700' }
          as="h3"
        >
          {faq.question}
        </Typography>

        <S.Arrow
          src={ ArrowIcon }
          $isOpen={ isOpen }
        />
      </S.Question>


      <AnimatePresence initial={ false }>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              'open': { 'opacity': 1, 'height': 'auto' },
              'collapsed': { 'opacity': 0, 'height': 0 }
            }}
            transition={{ 'duration': 0.3, 'ease': [ 0.04, 0.62, 0.23, 0.98 ] }}
          >
            <S.Answer>
              <Typography
                variant="text-medium-regular"
                color="TAG1-700"
                as="p"
              >
                <ReactMarkdown
                  markdown={ faq.answer }
                />
              </Typography>
            </S.Answer>
          </motion.div>
        )}
      </AnimatePresence>
    </S.Container>
  )
}

namespace FaqCard{
  export interface Props {
    faq: Faq
  }
}

export default FaqCard
