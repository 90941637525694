/* eslint-disable new-cap */
import React, { useState } from 'react'
import Banner from '../components/organisms/Banner'
import EmptyDiv from '../components/EmptyDiv/EmptyDiv'
import Footer from '../components/Footer/Footer'
import HowItWorks from '@site/components/organisms/HowItWorks'
import Menu from '../components/Menu/Menu'
import Newsletter from '../components/Newsletter/Newsletter'
import { graphql } from 'gatsby'
import useChatbot from '../helpers/useChatbot'
import VideoSection from '@site/components/organisms/VideoSection'
import CookiesFab from '@site/components/organisms/CookiesFab'
import Faq from '@site/components/organisms/Faq'
import SubscriptionOffer from '@site/components/organisms/SubscriptionOffer'
import CommomHead from '@shared/meta/CommonHead'


export function Head() {
  return (
    <CommomHead />
  )
}

export default function Home( { data }: Record<string, any> ) {
  const { 'allStrapiHomes': { 'nodes': [ { newBanner, clubs } ] } } = data
  const { 'allStrapiFaq': { 'edges': [ ...faqs ] } } = data
  const [ renderAllElements, setRenderAllElements ] = useState( false )

  useChatbot()

  function handleAllElements() {
    setRenderAllElements( true )
  }

  return (
    <>
      <Menu />
      <Banner
        title={ newBanner.title }
        subtitle={ newBanner.subtitle }
        image={ newBanner.image }
        clock={ newBanner.clock }
        button={ newBanner.button }
        warning={ newBanner.warning }
      />
      <EmptyDiv handleAllElements={ handleAllElements } />
      { renderAllElements ?
        <>
          <VideoSection show={ false } />
          <HowItWorks page="home" />
          <SubscriptionOffer { ...clubs } />
          <Newsletter />
          <Faq faqs={ faqs } />
          <Footer />
        </> :
        <div style={{ 'height': '500px' }} />}
      <CookiesFab />
    </>
  )
}

export const query = graphql`
{
  allStrapiHomes(limit: 1, sort: {order: DESC, fields: strapiId}) {
    nodes {
      newBanner {
        button {
          backgroundColor
          hoverColor
          link
          text
          textColor
        }
        clock {
          active
          backgroundColor
          borderColor
          date
          textColor
        }
        image {
          desktop5000x1600 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          mobile1200x1200 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        subtitle {
          color
          style
          text
        }
        title {
          color
          style
          text
          weight
        }
        warning {
          text
          color
        }
      }
      clubs {
        subtitle
        title
        image {
          mobile {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          desktop {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        description
        price
        cta {
          text
          link
        }
        footer
      }
    }
  }
  allStrapiFaq(filter: {display: {home: {eq: true}}}, sort: {order: ASC, fields: strapiId}) {
    edges {
      node {
        eventLabel
        question
        answer
      }
    }
  }
}
`
