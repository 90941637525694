import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const EmptyDivWrapper = styled.div`
  height: 1px;
`
const EmptyDiv = ( { handleAllElements } ) => {
  const observer = useRef()

  const emptyDivRef = useCallback( node => {
    observer.current = new IntersectionObserver( ( entries ) => {
      if ( entries[ 0 ].isIntersecting ) {
        observer.current.disconnect()
        handleAllElements()
      }
    } )

    if ( node ) {
      observer.current.observe( node )
    }
  }, [ handleAllElements ] )

  return (
    <EmptyDivWrapper ref={ emptyDivRef } />
  )
}

EmptyDiv.propTypes = {
  'handleAllElements': PropTypes.func.isRequired,
}


export default EmptyDiv
