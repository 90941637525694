import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { isMobile } from 'react-device-detect'
import BannerContent from '@site/components/molecules/BannerContent'
import { ReactComponent as MobileAnimation } from '@site/images/banner/mobile-animation.svg'
import { ReactComponent as DesktopAnimation } from '@site/images/banner/desktop-animation.svg'
import { HomeBanner } from '@shared/interfaces/HomeBanner'

const Banner = ( { title, subtitle, image, clock, button, warning }: Banner.Props ) => {
  const mobileBanner = getImage( image.mobile1200x1200.localFile )
  const desktopBanner = getImage( image.desktop5000x1600.localFile )
  const mobileBannerImage = convertToBgImage( mobileBanner )
  const desktopBannerImage = convertToBgImage( desktopBanner )
  const [ backgroundImage, setBackgroundImage ] = useState( {} as {} | null )

  useEffect( () => {
    setBackgroundImage( isMobile ? mobileBannerImage : desktopBannerImage )
  }, [] )

  return (
    <S.Container>
      <S.Image
        { ...backgroundImage }
        className="banner-image"
      >
        <BannerContent
          title={ title }
          subtitle={ subtitle }
          clock={ clock }
          button={ button }
          warning={ warning }
        />
      </S.Image>

      <MobileAnimation className="mobile-animation" />
      <DesktopAnimation className="desktop-animation" />
    </S.Container>
  )
}

namespace Banner {
  export interface Props {
    title: HomeBanner.Title
    subtitle: HomeBanner.Subtitle | null
    image: HomeBanner.Image
    clock: HomeBanner.Clock | null
    button: HomeBanner.Button
    warning: HomeBanner.Warning | null
  }
}

export default Banner
