import styled, { css } from 'styled-components'

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(19, 26, 45, 0.2);
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const Question = styled.div<Question.Props>`
  transition: all .3s;
  padding: ${props => props.$isOpen ? '16px 24px 4px' : '16px 24px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h3 {
    transition: all .3s;
    min-height: 24px;
    width: calc(100% - 32px);
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    padding: ${props => props.$isOpen ? '16px 32px 4px' : '16px 32px'};
  }
`

namespace Question {
  export interface Props {
    $isOpen: boolean
  }
}

const Arrow = styled.img<Arrow.Props>`
  transition: all .3s;

  ${props => props.$isOpen && css`
    transform: rotate(-180deg);
  `}
`

namespace Arrow {
  export interface Props {
    $isOpen: boolean
  }
}

const Answer = styled.div`
  padding: 0 24px 16px;
  
  > p * {
    font-family: inherit;
  }

  a {
    text-decoration-line: underline;
    color: #222F64;

    &:hover{
      color: #767BA7;
    }
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    padding: 0 32px 16px;
  }
`

export { Container, Question, Arrow, Answer }
