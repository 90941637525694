import { tagTheme } from '@taglivros/tag-components'
import styled from 'styled-components'

const Container = styled.div<Container.Props>`
  padding: 24px;
  background-color: ${props => props.theme.cores[ props.$backgroundColor ]}
`

namespace Container {
  export interface Props {
    $backgroundColor: keyof typeof tagTheme.cores
  }
}


export { Container }
