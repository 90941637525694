import React from 'react'
import * as S from './styles'
import ReactMarkdown from 'react-showdown'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { HomeBanner } from '@shared/interfaces/HomeBanner'

const BannerWarning = ( { data }: BannerWarning.Props ) => {
  return (
    <S.Container
      $color={ data.color }
      className="home-banner-warning"
    >
      <Typography
        as="small"
        variant="text-small-regular"
        color="WHITE"
      >
        <ReactMarkdown
          markdown={ data.text }
        />
      </Typography>
    </S.Container>
  )
}

namespace BannerWarning {
  export interface Props {
    data: HomeBanner.Warning
  }
}

export default BannerWarning
