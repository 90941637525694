/* eslint-disable react/iframe-missing-sandbox */
import { tagTheme } from '@taglivros/tag-components'
import React, { ReactElement } from 'react'
import ReactPlayer from 'react-player/youtube'
import * as S from './styles'

const VideoWrapper = ( { backgroundColor = 'none', videoUrl, videoHeight, videoWidth }: VideoWrapper.Props ): ReactElement => (
  <S.Container
    $backgroundColor={ backgroundColor }
    className="video-wrapper"
  >
    <ReactPlayer
      controls={ true }
      height={ videoHeight }
      url={ videoUrl }
      width={ videoWidth }
    />
  </S.Container>
)

namespace VideoWrapper {
  export interface Props {
    backgroundColor?: keyof typeof tagTheme.cores
    videoUrl: string
    videoHeight: string
    videoWidth: string
  }
}
export default VideoWrapper
