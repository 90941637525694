import styled from 'styled-components'
import faqBackground from '@site/images/faq/fundo-opaco.png'

const Container = styled.section`
  padding: 24px 16px;
  max-width: 100%;
  background-image: url(${faqBackground});
  background-repeat: repeat;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    padding: 32px 16px;
  }
`

const Content = styled.div`
  max-width: 800px;
  margin: auto;

  h2 {
    text-align: center;
    margin-bottom: 32px;
  }
`

export { Container, Content }
