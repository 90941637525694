import styled from 'styled-components'

const Container = styled.div`
  margin: auto;
  min-height: 272px;
  max-height: 368px;
  max-width: 400px;
  padding: 24px 24px 0;
  text-align: center;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: flex;
    justify-content: start;
    align-items: center;
    max-width: 1200px;
    height: 100%;
    max-height: 600px;
    padding: 0 40px;
    text-align: left;
  }
`

const ContainerHelper = styled.div`

`

export { Container, ContainerHelper }
