import React from 'react'
import * as S from './styles'
import BannerTitle from '@site/components/atoms/BannerTitle'
import BannerSubtitle from '@site/components/atoms/BannerSubtitle'
import BannerButton from '@site/components/atoms/BannerButton'
import Clock from '@site/components/atoms/Clock'
import BannerWarning from '@site/components/atoms/BannerWarning'
import { HomeBanner } from '@shared/interfaces/HomeBanner'

const BannerContent = ( { title, subtitle, clock, button, warning }: Banner.Props ) => {
  return (
    <S.Container>
      <S.ContainerHelper>
        <BannerTitle data={ title } />
        <BannerSubtitle data={ subtitle } />
        {clock?.active && <Clock data={ clock } />}
        {button.text && <BannerButton data={ button } />}
        {warning?.text && <BannerWarning data={ warning } />}
      </S.ContainerHelper>
    </S.Container>
  )
}

namespace Banner {
  export interface Props {
    title: HomeBanner.Title
    subtitle: HomeBanner.Subtitle | null
    clock: HomeBanner.Clock | null
    button: HomeBanner.Button
    warning: HomeBanner.Warning | null
  }
}

export default BannerContent
