/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import * as S from './styles'
import VideoSectionHeader from '@site/components/molecules/VideoSectionHeader'
import VideoWrapper from '@site/components/molecules/VideoWrapper'
import { isMobile } from 'react-device-detect'
import { ReactComponent as RoundHelperSVG } from '@site/assets/helpers/rounded-top.svg'
import { VideoSectionTexts } from '@shared/interfaces/VideoSectionTexts'
import Button from '@site/components/atoms/Button'

const VideoSection = ( { show }: VideoSection.Props ): React.ReactElement => {
  return (
    <>
      { show && (
        <S.Container className="video-section">
          <VideoSectionHeader />
          <S.Content className="video-section__content">
            <RoundHelperSVG
              height="14"
              width="100%"
              className="video-section__round-helper"
            />
            <VideoWrapper
              videoUrl="https://youtu.be/lRpBV_dgiOA"
              videoHeight={ isMobile ? '100%' : '400px' }
              videoWidth={ isMobile ? '100%' : '711px' }
            />
            <S.ButtonWrapper className="video-section__button-wrapper">
              <Button
                backgroundColor="TAG2-650-TXT"
                color="WHITE"
                className="video-section__button"
                href="https://clube.taglivros.com/aniversariotag/"
                hover="TAG2-700"
              >
                { VideoSectionTexts.LEARN_MORE }
              </Button>
            </S.ButtonWrapper>
          </S.Content>
        </S.Container>
      ) }
    </>
  )
}

namespace VideoSection {
  export interface Props {
    show: boolean
  }
}

export default VideoSection
