import React from 'react'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { HomeBanner } from '@shared/interfaces/HomeBanner'

const BannerButton = ( { data }: BannerButton.Props ) => {
  return (
    <S.Container
      $backgroundColor={ data.backgroundColor }
      $hoverColor={ data.hoverColor }
      $textColor={ data.textColor }
      href={ data.link }
      className="home-banner-button"
    >
      <Typography
        variant="button-medium-bold"
        color="WHITE"
      >
        {data.text}
      </Typography>
    </S.Container>
  )
}

namespace BannerButton {
  export interface Props {
    data: HomeBanner.Button
  }
}

export default BannerButton
