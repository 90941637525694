import styled from 'styled-components'

const Container = styled.div<Container.Props>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  margin-bottom: 16px;
  padding: 2px 16px;
  border: ${props => props.$borderColor ? `2px solid ${props.$borderColor}` : 'none'};
  border-radius: 4px;
  background-color: ${props => props.$backgroundColor || 'transparent'};
  color: ${props => props.$textColor};
  
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    height: 36px;
    margin-bottom: 24px;
    padding: 6px 20px;
  }
`

namespace Container {
  export interface Props {
    $backgroundColor: string
    $borderColor: string
    $textColor: string
  }
}

const TimeItem = styled.span`
  font-family: 'Source Code Pro';
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.05em;

  &:not(:last-child):after {
    content: ':';
  }
`

export { Container, TimeItem }
