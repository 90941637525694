import styled from 'styled-components'

const Container = styled.div`
  background-color: ${props => props.theme.cores[ 'TAG1-900' ]};
  padding-bottom: 24px;
`
const Content = styled.div`
  position: relative;
  .video-section__round-helper {
    top: -1px;
    transform: rotate(180deg);
    position: absolute;
    path {
      fill: ${props => props.theme.cores[ 'GREY-50-BG' ]};
    }
  }
  .video-wrapper > div {
    position: relative;
    margin-top: -56px;
    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  padding: 0 24px;
  > a {
    margin: auto;
    width: 100%;
    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      width: 220px;
    }
  }
`

export { Content, Container, ButtonWrapper }
