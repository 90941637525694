import React from 'react'
import * as S from './styles'
import { Typography } from '@taglivros/tag-components'
import { VideoSectionTexts } from '@shared/interfaces/VideoSectionTexts'

const VideoSectionHeader = () => {
  return (
    <S.Container className="video-section-header">
      <Typography
        as="h1"
        color="TAG1-700"
        fontFamily="circularStd"
        fontSizeDesktop="title-l"
        fontSizeMobile="title-p"
        variant="customText"
        fontWeight="heavy"
      >
        { VideoSectionTexts.BIRTHDAY_TAG } <strong> { VideoSectionTexts.WATCH }</strong>
      </Typography>
    </S.Container>
  )
}

export default VideoSectionHeader
