import styled from 'styled-components'

const Container = styled.div`
  padding: 32px 24px 48px;
  background-color: ${props => props.theme.cores[ 'GREY-50-BG' ]};
  text-align: center;
  strong { 
    color: ${props => props.theme.cores[ 'TAG1-600' ]}}; 
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    h1 {
      margin: auto;
      width: 1020px;
    }
  }
`

export { Container }
