import styled from 'styled-components'

const Container = styled.a<Container.Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 0 8px;
  background-color: ${props => props.$backgroundColor};
  text-transform: uppercase;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(19, 26, 45, 0.2);

  &:hover {
    background-color: ${props => props.$hoverColor};
  }

  > span {
    color: ${props => props.$textColor};
  }
  
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    margin: 0;
    width: fit-content;
    min-width: 150px;
  }
`

namespace Container {
  export interface Props {
    $backgroundColor: string
    $hoverColor: string
    $textColor: string
  }
}

export { Container }
