import styled from 'styled-components'

const Container = styled.div<Container.Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  margin-bottom: 16px;

  h2 * {
    color: ${props => props.$color};
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
  }

  h2 strong {
    letter-spacing: -0.03em;
    font-weight: 700;
  }
  
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    width: 350px;
    margin-bottom: 24px;
  }
`

namespace Container {
  export interface Props {
    $color: string
  }
}

export { Container }
