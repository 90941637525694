import styled, { keyframes } from 'styled-components'
import GatsbyBackgroundImage from 'gatsby-background-image'

const animationPath1 = keyframes`
  to {
    stroke-dashoffset: -300;
  }
`

const animationPath2 = keyframes`
  to {
    stroke-dashoffset: 600;
  }
`

const animationPath3 = keyframes`
  to {
    stroke-dashoffset: -150;
  }
`

const animationPath4 = keyframes`
  to {
    stroke-dashoffset: 850;
  }
`

const animationPath5 = keyframes`
  to {
    stroke-dashoffset: 1600;
  }
`

const animationPath6 = keyframes`
  to {
    stroke-dashoffset: 2600;
  }
`

const Container = styled.section`
  position: relative;
  margin-top: 48px;
  height: 675px;
  overflow: hidden;

  .mobile-animation {
    display: none;

    @media(max-width: 1024px) {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      > path:nth-of-type(2) {
        stroke-dasharray: 150,300;
        stroke-dashoffset: 150;
        animation: ${animationPath1} 10s linear infinite;
      }

      > path:nth-of-type(3) {
        stroke-dasharray: 150,300;
        stroke-dashoffset: 150;
        animation: ${animationPath2} 10s linear infinite;
      }

      > path:nth-of-type(4) {
        stroke-dasharray: 150,300;
        stroke-dashoffset: 300;
        animation: ${animationPath3} 10s linear infinite;
      }

      > path:nth-of-type(5) {
        stroke-dasharray: 150,300;
        stroke-dashoffset: 400;
        animation: ${animationPath4} 10s linear infinite;
      }
    }
  }

  .desktop-animation {
    display: none;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      > path:nth-of-type(2) {
        stroke-dasharray: 600,1000;
        stroke-dashoffset: 0;
        animation: ${animationPath5} 15s linear infinite;
      }

      > path:nth-of-type(3) {
        stroke-dasharray: 600,1000;
        stroke-dashoffset: 1000;
        animation: ${animationPath6} 15s linear infinite;
      }
    }
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    margin-top: 64px;
    height: 800px;
  }
`

const Image = styled( GatsbyBackgroundImage )`
  height: 600px;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    height: 800px;
  }
`

export { Container, Image }
