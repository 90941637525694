import React from 'react'
import * as S from './styles'
import ReactMarkdown from 'react-showdown'
import { Variant } from '@taglivros/tag-components/dist/themes/v2/typography'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { HomeBanner } from '@shared/interfaces/HomeBanner'

const BannerSubtitle = ( { data }: BannerSubtitle.Props ) => {
  const variant = (): Variant => {
    let variantSize: 'large' | 'medium'

    if ( data!.style !== 'auto' ) {
      variantSize = data!.style
    } else {
      const subtitleLength = data!.text.length

      if ( subtitleLength <= 65 ) {
        variantSize = 'large'
      } else {
        variantSize = 'medium'
      }
    }

    return `subtitle-${variantSize}-regular`
  }

  return (
    <S.Container
      $color={ data ? data.color : 'transparent' }
      className="home-banner-subtitle"
    >
      {data && (
        <Typography
          as="h2"
          variant={ variant() }
          color="WHITE"
        >
          <ReactMarkdown
            markdown={ data.text }
          />
        </Typography>
      )}

    </S.Container>
  )
}

namespace BannerSubtitle {
  export interface Props {
    data: HomeBanner.Subtitle | null
  }
}

export default BannerSubtitle
