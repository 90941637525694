import React from 'react'
import * as S from './styles'
import ReactMarkdown from 'react-showdown'
import { Variant } from '@taglivros/tag-components/dist/themes/v2/typography'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { HomeBanner } from '@shared/interfaces/HomeBanner'

const BannerTitle = ( { data }: BannerTitle.Props ) => {
  const variant = (): Variant => {
    let variantSize: 'huge' | 'big' | 'large' | 'medium'
    let variantWeight: 'light' | 'regular' | 'bold'

    if ( data.style !== 'auto' ) {
      variantSize = data.style
    } else {
      const titleLength = data.text.length

      if ( titleLength <= 20 ) {
        variantSize = 'huge'
      } else if ( titleLength <= 30 ) {
        variantSize = 'big'
      } else if ( titleLength <= 40 ) {
        variantSize = 'large'
      } else {
        variantSize = 'medium'
      }
    }

    if ( !data.weight ) {
      variantWeight = 'regular'
    } else {
      variantWeight = data.weight
    }

    return `title-${variantSize}-${variantWeight}`
  }

  return (
    <S.Container
      $color={ data.color }
      className="home-banner-title"
    >
      <Typography
        as="h1"
        variant={ variant() }
        color="WHITE"
      >
        <ReactMarkdown
          markdown={ data.text }
        />
      </Typography>
    </S.Container>
  )
}

namespace BannerTitle {
  export interface Props {
    data: HomeBanner.Title
  }
}

export default BannerTitle
