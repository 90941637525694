import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { HomeBanner } from '@shared/interfaces/HomeBanner'
import { useCountDown } from '@taglivros/tag-components'

const Clock = ( { data }: Clock.Props ) => {
  const { days, hours, minutes, seconds, expired } = useCountDown( new Date( data.date ).getTime(), 4 )
  const [ shouldAdd24Hours, setShouldAdd24Hours ] = useState( false )

  useEffect( () => {
    setShouldAdd24Hours( days === 1 )
  }, [ days ] )

  return expired ? (
    null
  ) : (
    <S.Container
      $backgroundColor={ data.backgroundColor }
      $borderColor={ data.borderColor }
      $textColor={ data.textColor }
      className="home-banner-clock"
    >
      {days > 1 && (
        <S.TimeItem className="home-banner-clock__days">
          {`${days}d`}
        </S.TimeItem>
      )}

      <S.TimeItem className="home-banner-clock__hours">
        {`${( shouldAdd24Hours ? hours + 24 : hours )?.toLocaleString( 'en-US', { 'minimumIntegerDigits': 2 } )}h`}
      </S.TimeItem>

      <S.TimeItem className="home-banner-clock__minutes">
        {`${minutes?.toLocaleString( 'en-US', { 'minimumIntegerDigits': 2 } )}m`}
      </S.TimeItem>

      <S.TimeItem className="home-banner-clock__seconds">
        {`${seconds?.toLocaleString( 'en-US', { 'minimumIntegerDigits': 2 } )}s`}
      </S.TimeItem>
    </S.Container>
  )
}

namespace Clock {
  export interface Props {
    data: HomeBanner.Clock
  }
}

export default Clock
