import styled from 'styled-components'

const Container = styled.div<Container.Props>`
  margin: 8px 8px 0;

  small * {
    font-family: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
    color: ${props => props.$color};
  }

  small strong {
    letter-spacing: -0.02em;
    font-weight: 700;
  }
  
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    margin: 8px 0 0;
    width: 350px;
  }
`

namespace Container {
  export interface Props {
    $color: string
  }
}

export { Container }
